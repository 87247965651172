@import 'src/styles';

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.email_only {
    justify-content: space-between;
    height: 100%;
  }
}

.fields_wrapper {
  width: 100%;

  &.for_reset {
    margin: 50px 0 20px;

    .password_field_wrap,
    .confirm_field_wrap {
      position: relative;

      &:first-child {
        margin-bottom: 20px;
      }

      .field {
        @include map-styles($resetPasswordFieldStyles);

        &:focus {
          border-color: $resetPasswordBorderColor;
        }

        &::placeholder {
          @include map-styles($resetPasswordPlaceholderStyles);
        }

        &.error_border {
          @include map-styles($resetPasswordErrorStyles);
        }

        &:disabled {
          opacity: $resetPasswordDisabledOpacity;

          &:hover {
            cursor: not-allowed;
          }

          border-bottom: 1px solid $createAccountBorderColor;
        }
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill-strong-password-viewable {
        border-bottom: 1px solid $resetPasswordPlaceholderColor !important;
        font-size: $fontSize20 !important;
        -webkit-background-clip: text;
        -webkit-text-fill-color: $resetPasswordFieldTextColor !important;
        transition: background-color 5000s ease-in-out 0s;
        box-shadow: inset 0 0 20px 20px transparent;
      }

      .error {
        color: $resetPasswordErrorColor;
        font-size: $fontSize12;
        font-weight: $fwsb;
        margin-top: 5px;
      }
    }

  }

  .field_wrap,
  .password_field_wrap,
  .confirm_field_wrap {
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 20px;

    .name {
      @include map-styles($createAccountInputNameStyles);

      &.with_label {
        display: block;
      }

      b {
        color: $createAccountAsteriskColor;
      }

      @include map-styles($createAccountInputNameStyles);
    }

    .field {
      width: 100%;
      border-radius: 10px;
      border: 1px solid $createAccountBorderColor;
      background: $white;
      padding: 15px 40px 15px 18px;
      font-size: $fontSize14;
      font-weight: $fwb;
      line-height: $fontSize24;
      color: $mineShaft;
      position: relative;

      @include map-styles($createAccountInputStyles);

      &::placeholder {
        color: $createAccountInputPlaceholderColor;

        @include map-styles($createAccountInputPlaceholderStyles);
      }

      &:focus {
        border-color: $createAccountActiveBorderColor;
      }

      &:disabled {
        opacity: $createAccountDisabledFieldOpacity;

        &:hover {
          cursor: not-allowed;
        }
      }

      &.error_border {
        border-color: $createAccountErrorColor;
      }
    }

    .error {
      color: $createAccountErrorColor;
      font-size: $fontSize12;
      font-weight: $fwn;
      margin-top: 3px;

      @include map-styles($createAccountErrorTextStyles);
    }

    .field_with_icon {
      .attantion_icon {
        width: 20px;
        height: 20px;
        color: $createAccountErrorColor;
      }
    }
  }

  .field_with_icon {
    position: relative;

    .field_icon {
      position: absolute;
      top: 50%;
      right: 15px;
      translate: 0 -50%;
      color: $createAccountInputIconsColor;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill-strong-password-viewable {
  font-size: $fontSize20 !important;
  -webkit-background-clip: text;
  -webkit-text-fill-color: $resetPasswordFieldTextColor !important;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px transparent;
}

.button {
  max-width: 100%;

  .button_title {
    font-size: $fontSize22;
  }

  &:disabled {
    background: $createAccountDisabledBtnColor;
    opacity: $createAccountDisabledBtnOpacity;

    .button_title {
      color: $createAccountDisabledTextColor;
    }
  }

  &.with_letter_icon {
    background-image: url(./icons/email.svg);
    background-position: center left 15px;
    background-repeat: no-repeat;
  }
}