$terminateBg: none;
$terminateTitleColor: var(--primaryColor);
$terminateTextColor: $mineShaft;
$terminateInputBg: #f7f7f7;
$terminateInputColor: inherit;
$terminateConfirmBg: rgba(247, 247, 247, 1);
$terminateConfirmHeaderBg: #ffffff;
$terminateConfirmHeaderColor: var(--primaryColor);
$terminateConfirmBlur: transparent;
$terminateConfirmWarningBg: #E1EEED;
$terminateConfirmWarningColor: #2D2D2D;
$terminateConfirmFeatureBg: #ffffff;
$terminateConfirmFeatureBorder: #ffffff;
$terminateConfirmFeatureTitleColor: #2D2D2D;
$terminateConfirmFeatureTitleSize: $fontSize30;
$terminateConfirmFeatureSubtitleColor: #2D2D2D;
$terminateConfirmFeatureShadow: 0 1px 4px rgba(183, 181, 170, 0.4);
$terminateConfirmFeaturesColor: #2D2D2D;
$terminateConfirmButtonColor: var(--primaryColor);
$terminateConfirmButtonBorder: var(--primaryColor);