@import "src/styles";

.points_wrapper {
  position: relative;
  height: 2.18rem;
}

.process {
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &__points {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;

    &__step_point {
      width: 20px;
      height: 20px;
      background-color: $white;
      border-radius: 50%;
      border: solid 3px $progressBarBgColor;
      flex-shrink: 0;
      position: relative;
      z-index: 11;
    }

    &__step_point_current {
      background-color: $progressBarActiveColor;
      border: solid 5px $progressCurrentStepPointColor;
    }

    &__step_point_done {
      background-color: $progressBarActiveColor;
      background-image: url(./images/check-mark.svg);
      background-repeat: no-repeat;
      background-position: center;
      border: solid 3px $progressBarActiveColor;
    }
  }
}